import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  makeStyles,
  Paper,
  Grid,
  TextField,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, updateUser } from 'features/session/session';
import { getCuisines, putPicture, putMenu } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { unwrapResult } from '@reduxjs/toolkit';
import EditIcon from '@material-ui/icons/Edit';
import fileToBase64 from 'utils/fileToBase64';
import routes from 'routes';
import { useHistory, Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    padding: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.data.user);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.form}>
        <Grid
          className={classes.root}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={4}
            >
              <Grid item>
                <Typography variant="subtitle1" color="textSecondary">
                  {t('Settings')}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="language">{t('Language')}</InputLabel>
                  <Select
                    labelId="language"
                    id="language"
                    value={i18n.languages[0]}
                    label={t('Cuisines')}
                    onChange={(e) => {
                      i18n.changeLanguage(e.target.value);
                    }}
                  >
                    <MenuItem value="fr">Français</MenuItem>
                    <MenuItem value="nl">Nederlands</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.buttonSubmit}
                  onClick={handleLogout}
                >
                  {t('Logout')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="strech"
              spacing={1}
            >
              <Grid item>
                <Typography variant="subtitle1" color="textSecondary">
                  {t('Social')}
                </Typography>
              </Grid>
              <Grid item>
                <Button href="mailto:hello@restolastminute.com" target="_blank">
                  {t('Contact us')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item>
                <Typography variant="subtitle1" color="textSecondary">
                  {t('Legal')}
                </Typography>
              </Grid>
              <Grid item>
                <Button href={t('url_terms')} target="_blank">
                  {t('Terms of service')}
                </Button>
              </Grid>
              <Grid item>
                <Button href={t('url_privacy')} target="_blank">
                  {t('Disclaimer')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
