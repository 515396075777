import React from 'react';
import Navigation from 'features/menu/Navigation';
import { useTranslation } from 'react-i18next';
import Settings from 'features/settings/Settings';

export default () => {
  const { t } = useTranslation();

  return (
    <Navigation title={t('Settings')}>
      <Settings />
    </Navigation>
  );
};
