import React from 'react';
import Navigation from 'features/menu/Navigation';
import menuList from 'features/menu';
import Restaurant from 'features/restaurants/Restaurant';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Navigation title={t('Restaurant')}>
      <Restaurant />
    </Navigation>
  );
};
