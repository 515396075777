import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Box,
  Avatar,
  TextField,
  Grid,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { signUpUser } from 'features/session/session';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { displayNotification } from 'components/notification/notifications';
import steps from './steps';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(4),
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonSubmit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ({ onSignupSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('signUp');
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [restaurantStreetAddress, setRestaurantStreetAddress] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [tosChecked, setTosChecked] = useState(false);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    try {
      const user = {
        email,
        password,
        restaurantName,
        restaurantAddress: restaurantStreetAddress,
        restaurantPostalCode: postalCode,
        restaurantCity: city,
      };
      const result = await dispatch(signUpUser(user));
      unwrapResult(result);
      setLoading(false);
      onSignupSuccess({ ...user, ...result });
    } catch (err) {
      setLoading(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const isFormValid =
    password &&
    email &&
    password === passwordConfirmation &&
    restaurantName &&
    restaurantStreetAddress &&
    postalCode &&
    city &&
    tosChecked;

  return (
    <Box className={classes.root}>
      <img className={classes.logo} src="logo.svg" />
      <Typography variant="h5">{t('Create an account')}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label={t('Email address')}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                password &&
                passwordConfirmation &&
                password !== passwordConfirmation
              }
              variant="outlined"
              required
              fullWidth
              label={t('Password')}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                password &&
                passwordConfirmation &&
                password !== passwordConfirmation
              }
              variant="outlined"
              required
              fullWidth
              label={t('Password Confirmation')}
              type="password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('Restaurant Name')}
              onChange={(e) => setRestaurantName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('Restaurant street address')}
              onChange={(e) => setRestaurantStreetAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('Postal code')}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('City')}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={tosChecked}
                  color="primary"
                  onChange={(e) => setTosChecked(e.target.checked)}
                />
              }
              label={
                <Trans i18nKey="Accept Terms and Conditions">
                  Accept
                  <Link href={t('url_terms')} target="_blank">
                    Terms and Conditions
                  </Link>
                </Trans>
              }
            />
          </Grid>
        </Grid>

        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isFormValid || loading}
            className={classes.buttonSubmit}
          >
            {t('Sign-Up')}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </form>
    </Box>
  );
};
