import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  makeStyles,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  clear,
  updateUser,
  signOut,
  fetchUser,
} from 'features/session/session';
import { getPaymentPlans, putSubscription } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import Progress from 'components/Progress';
import routes from 'routes';
import PaymentMethod from 'features/common/PaymentMethod';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import { Elements } from '@stripe/react-stripe-js';
import Subscriptions from './Subscriptions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    padding: theme.spacing(4),
  },
}));

const stripePromise = loadStripe(config.stripe.apiKey);

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user, subscription } = useSelector((state) => state.session.data);
  const planId =
    subscription && subscription.plan ? subscription.plan.id : null;
  const [name, setName] = useState(user.legalName);
  const [vat, setVat] = useState(user.vatNumber);
  const [email, setEmail] = useState(user.email);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const result = await dispatch(
        updateUser({
          ...user,
          legalName: name,
          vatNumber: vat,
          email,
        }),
      );
      unwrapResult(result);
      dispatch(
        displayNotification({
          message: t('Modifications saved'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleSubscriptionUpdated = async () => {
    dispatch(
      displayNotification({
        message: t('Subscription updated'),
        options: {
          variant: 'success',
        },
      }),
    );
  };

  const handlePaymentSuccess = async () => {
    dispatch(
      displayNotification({
        message: t('Payment Method Updated'),
        options: {
          variant: 'success',
        },
      }),
    );
  };

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="sm"
      disableGutters={true}
    >
      <Paper className={classes.form}>
        <Grid container direction="column" spacing={2}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('Legal name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('VAT')}
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('Admin email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  to={routes.FORGOT_PASSWORD}
                  variant="body2"
                >
                  {t('Reset password')}
                </Link>
              </Grid>
              <Grid item xs={12} align="end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.buttonSubmit}
                >
                  {t('Update')}
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid item>
            <Subscriptions
              planId={planId}
              onSubscriptionUpdated={handleSubscriptionUpdated}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle1" color="textSecondary">
              {t('Payment method')}{' '}
            </Typography>
            <Typography variant="body1">
              {subscription.paymentMethod.name}:{' '}
              {subscription.paymentMethod.description}
            </Typography>
          </Grid>

          <Grid item>
            <Elements stripe={stripePromise}>
              <PaymentMethod
                planId={planId}
                onPaymentSuccess={handlePaymentSuccess}
                paymentMethod={subscription.paymentMethod}
              />
            </Elements>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
