import superagent from 'superagent';
import config from 'config';
import authorizationHeader from 'features/session/authorizationHeader';
import i18n from 'i18n';
import PaymentRequired from './PaymentRequired';
import ErrorRequest from './ErrorRequest';
import Unauthorized from './Unauthorized';

const endpoint = config.apiEndpoint;

const makeAuthorizationToken = () => {
  const token = localStorage.getItem('userToken');
  const id = localStorage.getItem('userId');

  if (id && token) {
    return authorizationHeader(id, token);
  }
  throw Error('no authorization token');
};

export const signIn = async (email, password) => {
  try {
    const res = await superagent
      .post(`${endpoint}/user/login`)
      .set('Accept-Language', i18n.language)
      .send({ email, password });
    return res.body;
  } catch (err) {
    if (err.status === 403) {
      // server returns Forbidden for wrong credentials ?
      throw new Unauthorized(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await superagent
      .post(`${endpoint}/user/forgotpassword`)
      .set('Accept-Language', i18n.language)
      .send({ email });
    return res.body;
  } catch (err) {
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getUser = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/user`)
      .set('Accept-Language', i18n.language)
      .set('Authorization', makeAuthorizationToken());
    return res.body;
  } catch (err) {
    if (err.status === 402) {
      throw new PaymentRequired(err.message);
    }
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const signUp = async (user) => {
  try {
    const res = await superagent
      .post(`${endpoint}/user/signup`)
      .set('Accept-Language', i18n.language)
      .send(user);
    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const sendVerificationEmail = async ({ id, email }) => {
  try {
    const res = await superagent
      .post(`${endpoint}/user/resendVerificationEmail`)
      .set('Accept-Language', i18n.language)
      .send({ id, email });
    return res.body;
  } catch (err) {
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getCuisines = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/cuisine`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body.cuisines;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putUser = async (user) => {
  try {
    const res = await superagent
      .put(`${endpoint}/user`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send(
        // api only accept a subset of fields
        // also we must enforce non null field to be empty
        {
          email: user.email,
          restaurantName: user.restaurantName,
          legalName: user.legalName,
          restaurantAddress: user.restaurantAddress,
          restaurantCity: user.restaurantCity,
          restaurantPostalCode: user.restaurantPostalCode,
          phone: user.phone,
          vatNumber: user.vatNumber,
          website: user.website || '',
          cuisineIds: user.cuisineIds,
          tablebookerId: user.tablebookerId,
          emailNotif: user.restaurantNotificationEmail,
        },
      );

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putPicture = async (restaurantId, picture64) => {
  try {
    const res = await superagent
      .put(`${endpoint}/restaurant/${restaurantId}/picture`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send({ picture: picture64 });

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putMenu = async (restaurantId, mimeType, menu64) => {
  try {
    const res = await superagent
      .put(`${endpoint}/restaurant/${restaurantId}/menu`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send({ menu: menu64, mimeType });

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getDeals = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/user/deal`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body.deals;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const postDeal = async (deal) => {
  try {
    const res = await superagent
      .post(`${endpoint}/user/deal`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send(deal);

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putDeal = async (dealId, deal) => {
  try {
    const res = await superagent
      .put(`${endpoint}/user/deal/${dealId}`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send(deal);

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const deleteDeal = async (dealId) => {
  try {
    const res = await superagent
      .delete(`${endpoint}/user/deal/${dealId}`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getReservations = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/user/reservation`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body.reservations;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getPaymentPlans = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/payment2/stripe/plan`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body.plans;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const postPaymentCheckout = async (planId, paymentMethod) => {
  try {
    const res = await superagent
      .post(`${endpoint}/payment2/stripe/checkout`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send({ planId, paymentMethod });

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putPaymentMethodUpdate = async (paymentMethod) => {
  try {
    const res = await superagent
      .put(`${endpoint}/payment2/stripe/method`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send({ paymentMethod });

    return res.body.paymentMethod;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getSetupIntent = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/payment2/stripe/setupintent`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);

    return res.body.setupIntentClientSecret;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const putSubscription = async (planId) => {
  try {
    const res = await superagent
      .put(`${endpoint}/subscription`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language)
      .send({ planId });

    return res.body;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};

export const getBillingUrl = async () => {
  try {
    const res = await superagent
      .get(`${endpoint}/payment2/stripe/billing`)
      .set('Authorization', makeAuthorizationToken())
      .set('Accept-Language', i18n.language);
    return res.body.url;
  } catch (err) {
    // not working because server does not set cors header
    if (err.response) {
      throw new ErrorRequest(
        err.response.headers['X-RLM-ErrorMessage'] || err.message,
      );
    }
    throw err;
  }
};
