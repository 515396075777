import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { getReservations } from 'api';
import { useSelector, useDispatch } from 'react-redux';
import { displayNotification } from 'components/notification/notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.data.user);
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const reservations = await getReservations(user);
        setReservations(
          reservations.map((r) => ({
            fullName: `${r.firstName} ${r.lastName}`,
            email: r.email,
            phone: r.phone,
            date: r.date,
            percent: r.percentage,
            places: r.places,
            time: `${r.hour}:${r.minute < 10 ? `0${r.minute}` : r.minute}`,
          })),
        );
      } catch (err) {
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    };

    fetchReservations();
  }, [user, dispatch]);

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="lg"
      disableGutters={true}
    >
      <MaterialTable
        columns={[
          {
            title: t('Customer'),
            field: 'fullName',
          },
          { title: t('Places'), field: 'places' },
          { title: t('Percent'), field: 'percent' },
          { title: t('Time'), field: 'time' },
          { title: t('Date'), field: 'date' },
          { title: t('Phone'), field: 'phone' },
          { title: t('Email'), field: 'email' },
        ]}
        data={reservations}
        // actions={[
        //   {
        //     icon: () => <DeleteIcon />,
        //     tooltip: t('cancel_booking'),
        //     onClick: (event, rowData) => alert(`You canceled ${rowData.name}`),
        //   },
        // ]}
        options={{
          showTitle: false,
          actionsColumnIndex: -1,
          search: true,
          exportButton: true,
          grouping: false,
          sorting: false,
          selection: false,
          draggable: false,
          paging: false,
        }}
      />
    </Container>
  );
};
