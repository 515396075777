import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import NotFound from 'components/NotFound';
import Deals from 'features/deals/Deals';
import useSession from 'features/session/useSession';
import PaymentRequired from 'api/PaymentRequired';
import Navigation from 'features/menu/Navigation';
import routes from 'routes';
import steps from 'features/signup/steps';
import Progress from 'components/Progress';
import { useTranslation } from 'react-i18next';
import SignInPage from './SigninPage';
import SignUpPage from './SignupPage';
import DealsPage from './DealsPage';
import RestaurantPage from './RestaurantPage';
import AccountPage from './AccountPage';
import BookingsPage from './BookingsPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import IndexPage from './IndexPage';
import SettingsPage from './SettingsPage';

const ProtectedRoute = ({ session, ...props }) => {
  if (session.initializing) {
    return <Progress />;
  }

  if (session.error && session.error.name === PaymentRequired.name) {
    return <Redirect to={`/sign-up#step=${steps.planSelection}`} />;
  }

  if (session.data && !session.data.subscription) {
    return <Redirect to={`/sign-up#step=${steps.planSelection}`} />;
  }

  if (session.data) {
    window.Intercom('boot', {
      app_id: 'c1at4zuv',
      email: session.data.user.email,
      user_id: session.data.user.id,
    });

    return <Route {...props} />;
  }

  return <Redirect to={routes.SIGN_IN} />;
};

export default () => {
  const session = useSession();

  return (
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <ProtectedRoute
        exact
        path={routes.DEALS}
        session={session}
        component={DealsPage}
      />
      <ProtectedRoute
        exact
        path={routes.RESTAURANT}
        session={session}
        component={RestaurantPage}
      />
      <ProtectedRoute
        exact
        path={routes.BOOKINGS}
        session={session}
        component={BookingsPage}
      />
      <ProtectedRoute
        exact
        path={routes.ACCOUNT}
        session={session}
        component={AccountPage}
      />
      <ProtectedRoute
        exact
        path={routes.SETTINGS}
        session={session}
        component={SettingsPage}
      />
      <Route exact path={routes.SIGN_IN} component={SignInPage} />
      <Route
        exact
        path={routes.FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <Route exact path={routes.SIGN_UP} component={SignUpPage} />
      <Route component={NotFound} />
    </Switch>
  );
};
