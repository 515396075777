import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDeals, postDeal, deleteDeal, putDeal } from 'api';

export const fetchDeals = createAsyncThunk(
  'fetchDeals',
  async (arg, { getState }) => {
    const { data } = getState().session;
    return getDeals(data.user);
  },
);

export const saveDeal = createAsyncThunk('saveDeal', async (deal) => {
  if (deal.id) {
    return putDeal(deal.id, deal);
  }
  return postDeal(deal);
});

export const removeDeal = createAsyncThunk(
  'removeDeal',
  async ({ dealId, index }) => {
    await deleteDeal(dealId);
    return index;
  },
);

const slice = createSlice({
  name: 'deals',
  initialState: {
    loading: false,
    data: [null, null, null, null, null, null, null],
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchDeals.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchDeals.fulfilled]: (state, action) => {
      state.loading = false;
      action.payload.forEach((d) => {
        state.data[d.days.findIndex((x) => x)] = d;
      });
    },
    [fetchDeals.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [saveDeal.fulfilled]: (state, action) => {
      state.loading = false;
      state.data[action.payload.days.findIndex((x) => x)] = action.payload;
    },
    [removeDeal.fulfilled]: (state, action) => {
      state.loading = false;
      state.data[action.payload] = null;
    },
  },
});

export default slice.reducer;
