import React from 'react';
import Navigation from 'features/menu/Navigation';
import menuList from 'features/menu';
import Account from 'features/account/Account';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Navigation title={t('Account')}>
      <Account />
    </Navigation>
  );
};
