import React from 'react';
import Deals from 'features/deals/Deals';
import Navigation from 'features/menu/Navigation';
import menuList from 'features/menu';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Navigation title={t('Deals')}>
      <Deals />
    </Navigation>
  );
};
