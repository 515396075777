import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#ff0091',
    },
    secondary: {
      main: '#ff0091',
    },
    typography: {
      useNextVariants: true,
    },
  },
});
