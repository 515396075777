import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser, signIn, putUser, signUp } from 'api';
import get from 'lodash.get';

export const fetchUser = createAsyncThunk('user', () => {
  return getUser();
});

export const signInUser = createAsyncThunk(
  'sign-in',
  async ({ email, password }, { dispatch }) => {
    const response = await signIn(email, password);
    localStorage.setItem('userId', response.user.id);
    localStorage.setItem('userToken', response.user.token);

    //  !!login doesn't return proper user infos
    return response;
  },
);

export const signUpUser = createAsyncThunk('sign-up', async (user) => {
  const response = await signUp(user);
  localStorage.setItem('userId', response.id);
  localStorage.setItem('userToken', response.token);
  return { ...user, ...response };
});

export const updateUser = createAsyncThunk('updateUser', async (user) => {
  await putUser(user);
  return user;
});

const slice = createSlice({
  name: 'user',
  initialState: {
    initializing: true,
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    clear: (state) => {
      state.initializing = false;
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  // BEWARE: its seems API does not return always the same result for get /user and /sign-in: /user and /sign-up
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.initializing = false;
      state.loading = false;
      state.data = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.initializing = false;
      state.loading = false;
      state.error = action.error;
    },
    [signInUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.error = null;
    },
    [signInUser.rejected]: (state, action) => {},
    [signUpUser.fulfilled]: (state, action) => {
      state.data = { user: action.payload };
      state.error = null;
    },
    [signUpUser.rejected]: (state, action) => {},
    [updateUser.fulfilled]: (state, action) => {
      state.data.user = action.payload;
    },
  },
});

export const { clear } = slice.actions;

export default slice.reducer;
