import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { numberCurrencyFormat } from 'utils/currency';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { getPaymentPlans } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    marginTop: theme.spacing(4),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

export default ({ onPlanSelectClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        const result = await getPaymentPlans();
        // hide the last point
        setPlans(result.slice(0, -1));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    };

    fetchPlans();
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>
        <ShoppingBasketIcon />
      </Avatar>
      <Typography variant="h5">{t('Select Payment Plan')}</Typography>
      <Grid
        className={classes.list}
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        {plans.map((plan) => (
          <Grid item key={plan.id} xs={12} sm={3}>
            <Card className={classes.card}>
              <CardHeader
                title={plan.name}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography variant="h6" color="textPrimary">
                    {plan.priceMultiLine}
                  </Typography>
                </div>
                <Typography variant="subtitle1" align="center">
                  {plan.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={plan.buttonVariant}
                  color="primary"
                  onClick={() => onPlanSelectClick(plan)}
                >
                  {t('Select')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
