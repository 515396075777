import React, { useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import {
  Typography,
  Grid,
  Button,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { sendVerificationEmail } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { displayNotification } from 'components/notification/notifications';
import { useHistory } from 'react-router-dom';
import steps from './steps';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

const ConfirmEmail = ({ email, onEmailValidatedClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.session.data.user);
  const [loading, setLoading] = useState(false);

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await sendVerificationEmail(user);
      setLoading(false);
      dispatch(
        displayNotification({
          message: t('confirmation email sent'),
          options: {
            variant: 'info',
          },
        }),
      );
    } catch (err) {
      setLoading(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      spacing={4}
    >
      <Grid item>
        <Avatar className={classes.avatar}>
          <EmailIcon />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {t(`A confirmation email has been sent to ${email}`)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">
          {t(`Please confirm your email before moving on to the next step`)}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onEmailValidatedClick}
            >
              {t(`Next`)}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleResendEmail}>{t(`Resend an Email`)}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmEmail;
