import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import routes from 'routes';
import queryString from 'query-string';

export default () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { userId, userToken } = parsed;

  if (userId && userToken) {
    localStorage.setItem('userId', userId);
    localStorage.setItem('userToken', userToken);
  }

  return <Redirect to={routes.DEALS} />;
};
