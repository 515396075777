import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Box,
  Avatar,
  TextField,
  Grid,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logDOM } from '@testing-library/react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default ({ onSignInClick, signUpUrl, forgotPasswordUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation('Login');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    onSignInClick({ email, password });
  };

  const isInvalid = password === '' || email === '';

  return (
    <Box className={classes.root}>
      <img className={classes.logo} src="logo.svg" />
      <Typography component="h1" variant="h5">
        {t('RestoLastMinute Pro')}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t('Email address')}
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t('Password')}
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          disabled={isInvalid}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t('Login')}
        </Button>
      </form>

      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to={forgotPasswordUrl} variant="body2">
            {t('Forgot password?')}
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={signUpUrl} variant="body2">
            {t('Sign-Up')}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
