import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import Routes from 'routes/Routes';
import { SnackbarProvider } from 'notistack';
import Notifier from 'components/notification/Notifier';

export default () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Notifier />
      <Routes />
    </SnackbarProvider>
  </BrowserRouter>
);
