import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  makeStyles,
  Paper,
  Grid,
  TextField,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, updateUser } from 'features/session/session';
import { getCuisines, putPicture, putMenu } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { unwrapResult } from '@reduxjs/toolkit';
import EditIcon from '@material-ui/icons/Edit';
import fileToBase64 from 'utils/fileToBase64';

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    padding: theme.spacing(4),
  },
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.session);
  const user = useSelector((state) => state.session.data.user);

  const [name, setName] = useState(user.restaurantName);
  const [address, setAddress] = useState(user.restaurantAddress);
  const [postalCode, setPostalCode] = useState(user.restaurantPostalCode);
  const [city, setCity] = useState(user.restaurantCity);
  const [country, setCountry] = useState(user.restaurantCountry);
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.restaurantNotificationEmail);
  const [website, setWebsite] = useState(user.website);
  const [cuisinesList, setCuisinesList] = useState([]);
  const [tablebookerId, setTablebookerId] = useState(user.tablebookerId);

  const [menu, setMenu] = useState();
  const [picture, setPicture] = useState();

  const [restaurantCuisineIds, setRestaurantCuisineIds] = React.useState(
    // for some reason cuisines is in String in getUser but Int in getCuisine
    user.cuisineIds.map((id) => parseInt(id, 10)),
  );
  useEffect(() => {
    const fetchCuisines = async () => {
      const result = await getCuisines(user);
      setCuisinesList(result);
    };

    fetchCuisines();
  }, [user]);

  const handleCuisineIdsChange = (event) => {
    if (event.target.value.length <= 2) {
      setRestaurantCuisineIds(event.target.value);
    }
  };

  const handlePictureChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const b64 = (await fileToBase64(file)).split(',')[1];
        await putPicture(user.restaurantId, b64);
        dispatch(
          displayNotification({
            message: t('Modifications saved'),
            options: {
              variant: 'success',
            },
          }),
        );
      } catch (err) {
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    }
  };

  const handleMenuChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const b64 = (await fileToBase64(file)).split(',')[1];
        await putMenu(user.restaurantId, file.type, b64);
        dispatch(
          displayNotification({
            message: t('Modifications saved'),
            options: {
              variant: 'success',
            },
          }),
        );
      } catch (err) {
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    }
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const result = await dispatch(
        updateUser({
          ...user,
          restaurantName: name,
          restaurantAdddress: address,
          restaurantPostalCode: postalCode,
          restaurantCity: city,
          phone,
          restaurantNotificationEmail: email,
          website,
          cuisineIds: restaurantCuisineIds,
          tablebookerId,
        }),
      );
      unwrapResult(result);
      dispatch(
        displayNotification({
          message: t('Modifications saved'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="sm"
      disableGutters={true}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Paper>
            <form onSubmit={handleSubmit}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <img
                src={user.pictureUrl}
                alt={t('Restaurant Name')}
                style={{ width: '100%', height: 'auto' }}
              />

              <Grid
                container
                className={classes.form}
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Restaurant Name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Address')}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Postal code')}
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('City')}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Phone')}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Booking email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Website')}
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="cuisines">{t('Cuisines')}</InputLabel>
                    <Select
                      labelId="cuisines"
                      id="cuisines"
                      multiple
                      value={restaurantCuisineIds}
                      onChange={handleCuisineIdsChange}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                      label={t('Cuisines')}
                    >
                      {cuisinesList.map((cuisine) => (
                        <MenuItem key={cuisine.id} value={cuisine.id}>
                          {cuisine.nameFr}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{t('2 cuisines max')}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <img src="tablebooker.jpg"></img>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Tablebooker ID')}
                    value={tablebookerId}
                    onChange={(e) => setTablebookerId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} align="end">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                  >
                    {t('Update')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="image/jpeg"
                    className={classes.input}
                    id="button-picture"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handlePictureChange}
                  />
                  <label htmlFor="button-picture">
                    <Button variant="text" component="span">
                      {t('Update picture')}
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="image/jpeg"
                    className={classes.input}
                    id="button-menu"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleMenuChange}
                  />
                  <label htmlFor="button-menu">
                    <Button variant="text" component="span">
                      {t('Update menu')}
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
