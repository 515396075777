import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  IbanElement,
  CardElement,
} from '@stripe/react-stripe-js';
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  Grid,
} from '@material-ui/core';
import { postPaymentCheckout, getSetupIntent } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { useDispatch, useSelector } from 'react-redux';
import StripeInput from 'utils/stripe/StripeInput';
import { fetchUser } from 'features/session/session';
import { unwrapResult } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  grid: {},
  form: {
    width: '100%',
  },
  buttonWrapper: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ({ planId, onPaymentSuccess, paymentMethod }) => {
  const classes = useStyles();
  const { t } = useTranslation('signUp');
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const user = useSelector((state) => state.session.data.user);

  const [loading, setLoading] = useState(false);

  const handleCardSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    try {
      const intentSecret = await getSetupIntent();

      const result = await stripe.confirmCardSetup(intentSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${user.firstName} ${user.lastName}`,
            address: {
              postal_code: user.postalCode,
            },
          },
        },
      });

      if (result.error) {
        throw new Error(
          result.error.message || result.error.code || 'unknown stripe error',
        );
      }
      await postPaymentCheckout(planId, result.setupIntent.payment_method);
      setLoading(false);
      const newUser = dispatch(fetchUser());
      unwrapResult(newUser);
      onPaymentSuccess();
    } catch (err) {
      setLoading(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleIbanSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    try {
      const intentSecret = await getSetupIntent();

      const result = await stripe.confirmSepaDebitSetup(intentSecret, {
        payment_method: {
          sepa_debit: elements.getElement(IbanElement),
          billing_details: {
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            address: {
              postal_code: user.postalCode,
            },
          },
        },
      });

      if (result.error) {
        throw new Error(
          result.error.message || result.error.code || 'unknown stripe error',
        );
      }
      await postPaymentCheckout(planId, result.setupIntent.payment_method);
      const newUser = dispatch(fetchUser());
      unwrapResult(newUser);
      setLoading(false);
      onPaymentSuccess();
    } catch (err) {
      setLoading(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing="2"
    >
      <Grid item>
        <form className={classes.form} onSubmit={handleCardSubmit}>
          <Grid container direction="column" spacing="2">
            <Grid item>
              <Typography variant="subtitle1">{t('Credit Card')}</Typography>
            </Grid>

            <Grid item>
              <TextField
                label={t('Credit Card Number')}
                name="ccnumber"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardElement,
                  },
                }}
              />
            </Grid>
            <Grid item align="end">
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={loading}
                >
                  {t('Validate Card')}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item>
        <form className={classes.form} onSubmit={handleIbanSubmit}>
          <Grid container direction="column" spacing="2">
            <Grid item>
              <Typography variant="subtitle1">{t('SEPA')}</Typography>
            </Grid>

            <Grid item>
              <TextField
                label={t('IBAN Number')}
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: IbanElement,
                    options: {
                      supportedCountries: ['SEPA'],
                      placeholderCountry: 'BE',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item align="end">
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {t('Validate IBAN')}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
