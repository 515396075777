import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Chip,
  Select,
  MenuItem,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Button,
  ExpansionPanelActions,
  InputLabel,
  FormControl,
  Grid,
  Slider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { displayNotification } from 'components/notification/notifications';
import Progress from 'components/Progress';
import { fetchDeals } from './deals';
import Deal from './Deal';
import days from './days';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.deals);

  const [expanded, setExpanded] = React.useState(null);

  useEffect(() => {
    try {
      const result = dispatch(fetchDeals());
      unwrapResult(result);
    } catch (err) {
      displayNotification({
        message: err.message,
        options: {
          variant: 'error',
        },
      });
    }
  }, [dispatch]);

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="md"
      disableGutters={true}
    >
      {loading ? (
        <Progress />
      ) : (
        days.map((d, i) => (
          <Deal
            day={d}
            index={i}
            deal={
              data[i] || {
                lunch: false,
                dinner: true,
                percentage: 30,
                places: 8,
                startHour: 19,
                startMinute: 0,
                endHour: 22,
                endMinute: 0,
              }
            }
            expanded={expanded === i}
            onChange={(panel) => (event, newExpanded) =>
              setExpanded(newExpanded ? panel : false)}
          />
        ))
      )}
    </Container>
  );
};
