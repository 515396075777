export default {
  DEALS: '/deals',
  BOOKINGS: '/bookings',
  RESTAURANT: '/restaurant',
  ACCOUNT: '/account',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/reset-password',
  SIGN_UP: '/sign-up',
  SETTINGS: '/settings',
};
