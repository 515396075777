import React, { useState } from 'react';
import { Typography, Container, makeStyles, Grid } from '@material-ui/core';
import SignIn from 'components/SignIn';
import { useHistory, Route } from 'react-router-dom';
import { signIn } from 'api';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, signInUser } from 'features/session/session';
import { unwrapResult } from '@reduxjs/toolkit';
import { displayNotification } from 'components/notification/notifications';
import routes from 'routes';
import Progress from 'components/Progress';
import Unauthorized from 'api/Unauthorized';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleSignInClick = async ({ email, password }) => {
    try {
      setLoading(true);
      const result = await dispatch(signInUser({ email, password }));
      const user = unwrapResult(result);
      await dispatch(fetchUser());
      setLoading(false);
      history.replace('/');
    } catch (err) {
      setLoading(false);

      const message =
        err.name === Unauthorized.name ? t('Credentials error') : err.message;

      dispatch(
        displayNotification({
          message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Grid
        style={{ minHeight: '90vh' }}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        {loading ? (
          <Progress />
        ) : (
          <SignIn
            onSignInClick={handleSignInClick}
            forgotPasswordUrl={routes.FORGOT_PASSWORD}
            signUpUrl={routes.SIGN_UP}
          />
        )}
      </Grid>
    </Container>
  );
};
