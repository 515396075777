import React, { useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Checkbox,
  Chip,
  Select,
  MenuItem,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Button,
  ExpansionPanelActions,
  InputLabel,
  FormControl,
  Grid,
  Slider,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { displayNotification } from 'components/notification/notifications';
import { saveDeal, removeDeal } from './deals';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  panelDetails: {
    margin: theme.spacing(4),
  },
  day: {
    flexGrow: 1,
  },
  slider: {
    width: 300,
    rail: {
      height: 8,
      borderRadius: 4,
    },
  },
}));

const CustomSlider = withStyles({
  track: {},
  mark: {},
  markActive: {},
})(Slider);

const lunchTimes = [
  { value: 0, label: '12:00', hour: 12, minute: 0 },
  { value: 1, label: '12:30', hour: 12, minute: 30 },
  { value: 2, label: '13:00', hour: 13, minute: 0 },
];

const dinnerTimes = [
  { value: 0, label: '18:00', hour: 18, minute: 0 },
  { value: 1, label: '18:30', hour: 18, minute: 30 },
  { value: 2, label: '19:00', hour: 19, minute: 0 },
  { value: 3, label: '19:30', hour: 19, minute: 30 },
  { value: 4, label: '20:00', hour: 20, minute: 0 },
  { value: 5, label: '20:30', hour: 20, minute: 30 },
  { value: 6, label: '21:00', hour: 21, minute: 0 },
  { value: 7, label: '21:30', hour: 21, minute: 30 },
  { value: 8, label: '22:00', hour: 22, minute: 0 },
];

export default ({ day, index, deal, expanded, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [lunch, setLunch] = React.useState(deal.lunch);
  const [dinner, setDinner] = React.useState(deal.dinner);
  const [percentage, setPercentage] = React.useState(deal.percentage);
  const [places, setPlaces] = React.useState(deal.places);

  const [dinnerSlider, setDinnerSlider] = React.useState([
    dinnerTimes.findIndex(
      (x) => x.hour === deal.startHour && x.minute === deal.startMinute,
    ),
    dinnerTimes.findIndex(
      (x) => x.hour === deal.endHour && x.minute === deal.endMinute,
    ),
  ]);

  // for i18next parser ignoring variable key
  t('Monday');
  t('Tuesday');
  t('Wednesday');
  t('Thursday');
  t('Friday');
  t('Saturday');
  t('Sunday');

  useEffect(() => {
    setLunch(deal.lunch);
    setDinner(deal.dinner);
    setPercentage(deal.percentage);
    setPlaces(deal.places);
    setDinnerSlider([
      dinnerTimes.findIndex(
        (x) => x.hour === deal.startHour && x.minute === deal.startMinute,
      ),
      dinnerTimes.findIndex(
        (x) => x.hour === deal.endHour && x.minute === deal.endMinute,
      ),
    ]);
  }, [deal]);

  const handleDinnerAndLunch = (dinnerChecked, lunchChecked) => {
    if (dinnerChecked || lunchChecked) {
      setDinner(dinnerChecked);
      setLunch(lunchChecked);
    }
  };

  const handlePause = async () => {
    try {
      const result = await dispatch(
        saveDeal({ ...deal, pause: deal.pause ? 0 : 1 }),
      );
      unwrapResult(result);
      dispatch(
        displayNotification({
          message: result.pause ? t('Deal paused') : t('Deal resumed'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleSave = async () => {
    const startTime = dinnerTimes[dinnerSlider[0]];
    const endTime = dinnerTimes[dinnerSlider[1]];
    const daysBooleans = Array(7).fill(false);
    daysBooleans[index] = true;
    try {
      const result = await dispatch(
        saveDeal({
          ...deal,
          days: daysBooleans,
          lunch,
          dinner,
          percentage,
          places,
          startHour: startTime.hour,
          startMinute: startTime.minute,
          endHour: endTime.hour,
          endMinute: endTime.minute,
          pause: deal.pause ? deal.pause : 0,
        }),
      );
      unwrapResult(result);
      dispatch(
        displayNotification({
          message: t('Deal saved'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const handleRemove = async () => {
    try {
      const result = await dispatch(removeDeal({ dealId: deal.id, index }));
      unwrapResult(result);
      dispatch(
        displayNotification({
          message: t('Deal cleared'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <ExpansionPanel expanded={expanded} onChange={onChange(index)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${day}-control`}
        id={`${day}-panel`}
      >
        <Typography className={classes.day} variant="subtitle1">
          {t(day)}
        </Typography>

        {deal.id ? (
          <Chip
            edge="end"
            color="secondary"
            label={`${deal.pause ? `${t('PAUSE')}:` : ''}${t(
              'deal_description',
              {
                places,
                percentage,
              },
            )} - ${deal.lunch ? t('Lunch') : ''} ${
              deal.dinner ? t('Dinner') : ''
            }`}
          />
        ) : (
          <Typography variant="overline">{t('No deal')}</Typography>
        )}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={classes.panelDetails}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={`${day}-select-percentage-label`}>
                {t('Percentage')}
              </InputLabel>
              <Select
                labelId={`${day}-select-percentage-label`}
                id={`${day}-select-percentage`}
                label={t('Percentage')}
                value={percentage}
                disabled={deal.pause}
                onChange={(e) => setPercentage(parseInt(e.target.value, 10))}
              >
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={15}>15%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
                <MenuItem value={30}>30%</MenuItem>
                <MenuItem value={35}>35%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={45}>45%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={55}>55%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id={`${day}-select-places-label`}>
                {t('Places')}
              </InputLabel>
              <Select
                labelId={`${day}-select-places-label`}
                id={`${day}-select-places`}
                label={t('Places')}
                value={places}
                disabled={deal.pause}
                onChange={(e) => setPlaces(parseInt(e.target.value, 10))}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={lunch}
                      disabled={deal.pause}
                      onChange={(e) =>
                        handleDinnerAndLunch(dinner, e.target.checked)
                      }
                    />
                  }
                  label={t('Lunch')}
                />
              </Grid>
              <Grid item>
                <CustomSlider
                  className={classes.slider}
                  step={1}
                  min={0}
                  max={lunchTimes.length - 1}
                  value={[0, lunchTimes.length - 1]}
                  valueLabelDisplay="auto"
                  aria-labelledby="lunch-slider"
                  getAriaValueText={(value) => lunchTimes[value].label}
                  marks={lunchTimes}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={dinner}
                      disabled={deal.pause}
                      onChange={(e) =>
                        handleDinnerAndLunch(e.target.checked, lunch)
                      }
                    />
                  }
                  label={t('Dinner')}
                />
              </Grid>
              <Grid item>
                <CustomSlider
                  className={classes.slider}
                  aria-labelledby={`${day}-dinner-slider`}
                  value={dinnerSlider}
                  disabled={deal.pause}
                  step={1}
                  min={0}
                  max={dinnerTimes.length - 1}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => ` ${dinnerTimes[value].label} `}
                  getAriaValueText={(value) => dinnerTimes[value].label}
                  marks={dinnerTimes.filter((x) => x.minute === 0)}
                  onChange={(e, value) => {
                    setDinnerSlider(value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button disabled={!deal.id} color="secondary" onClick={handlePause}>
          {deal.pause ? t('Resume') : t('Pause')}
        </Button>
        <Button
          disabled={!deal.id || deal.pause}
          color="secondary"
          onClick={handleRemove}
        >
          {t('remove')}
        </Button>
        <Button disabled={deal.pause} color="secondary" onClick={handleSave}>
          {t('Save Deal')}
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};
