import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  makeStyles,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { getPaymentPlans, putSubscription } from 'api';
import { displayNotification } from 'components/notification/notifications';
import { useDispatch } from 'react-redux';
import { fetchUser } from 'features/session/session';
import { unwrapResult } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ({ planId, onSubscriptionUpdated }) => {
  const classes = useStyles();
  const { t } = useTranslation('signUp');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [plans, setPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(planId);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const result = await getPaymentPlans();
        setPlans(result);
      } catch (err) {
        dispatch(
          displayNotification({
            message: err.message,
            options: {
              variant: 'error',
            },
          }),
        );
      }
    };

    fetchPlans();
  }, [dispatch]);

  const handleSubscriptionUpdate = async () => {
    try {
      setLoading(true);
      await putSubscription(selectedPlanId);
      setLoading(false);
      const user = await dispatch(fetchUser());
      unwrapResult(user);
      onSubscriptionUpdated();
    } catch (err) {
      setLoading(false);
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary">
          {t('Subscription')}{' '}
        </Typography>
      </Grid>
      <Grid item>
        <RadioGroup
          aria-label="plan"
          name="plan"
          value={selectedPlanId}
          onChange={(e) => setSelectedPlanId(e.target.value)}
        >
          {plans.map((plan) => (
            <FormControlLabel
              key={plan.id}
              value={plan.id}
              control={<Radio />}
              label={
                <div>
                  <Typography variant="subtitle1">
                    {plan.name}: <b>{plan.priceMultiLine}</b>
                  </Typography>
                </div>
              }
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item align="end">
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSubscriptionUpdate}
            disabled={loading}
          >
            {t('Update subscription')}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
