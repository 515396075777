import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {
  Typography,
  Grid,
  Button,
  Avatar,
  makeStyles,
  Container,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { displayNotification } from 'components/notification/notifications';
import { forgotPassword } from 'api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data } = useSelector((state) => state.session);

  const [email, setEmail] = useState(data && data.user ? data.user.email : '');

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      await forgotPassword(email);
      history.replace('/sign-in');
      dispatch(
        displayNotification({
          message: t('An email with reset instructions has been sent'),
          options: {
            variant: 'success',
          },
        }),
      );
    } catch (err) {
      dispatch(
        displayNotification({
          message: err.message,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  };

  const isInvalid = email === '';

  return (
    <Container className={classes.root} maxWidth="sm">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Avatar className={classes.avatar}>
            <HelpIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">{t(`Reset Password`)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {t(
              `Enter your email and receive instruction on how to reset your password`,
            )}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={t('Email address')}
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={isInvalid}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t(`Send an Email`)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPasswordPage;
