import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

export default () => {
  return (
    <Grid
      style={{ minHeight: '80vh' }}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  );
};
