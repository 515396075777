import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Link,
  Grid,
  Button,
  AppBar,
  Toolbar,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import { Elements } from '@stripe/react-stripe-js';
import routes from 'routes';
import { fetchUser } from 'features/session/session';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SignUp from './SignUp';
import steps from './steps';
import PlanSelection from './PlanSelection';
import PaymentMethodValidation from '../common/PaymentMethod';
import ConfirmEmail from './EmailConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  avatarItem: {
    marginBottom: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  stepper: {
    background: '#00000000',
  },
}));

const stripePromise = loadStripe(config.stripe.apiKey);

export default () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(
    queryString.parse(location.hash).step || steps.accountCreation,
  );
  const [selectedPlan, setSelectedPlan] = useState(' ');

  useEffect(() => {
    setActiveStep(
      queryString.parse(location.hash).step || steps.accountCreation,
    );
  }, [location.hash]);

  const handleSignupSuccess = async (user) => {
    history.push(`/sign-up#step=${steps.planSelection}`);
    window.Intercom('boot', {
      app_id: 'c1at4zuv',
      email: user.email,
      user_id: user.id,
      created_at: Date.now(),
    });
  };

  const handleSelectPlanClick = async (plan) => {
    setSelectedPlan(plan);
    history.push(`/sign-up#step=${steps.paymentMethodValidation}`);
  };

  const handlePaymentSuccess = async () => {
    await dispatch(fetchUser());
    history.replace(`/`);
  };

  const stepsComponents = {
    [steps.accountCreation]: {
      stepperTitle: t('Create an account'),
      component: (
        <Container maxWidth="sm">
          <SignUp onSignupSuccess={handleSignupSuccess} />
        </Container>
      ),
    },
    [steps.planSelection]: {
      stepperTitle: t('Select payment plan'),
      component: (
        <Container maxWidth="lg">
          <PlanSelection onPlanSelectClick={handleSelectPlanClick} />
        </Container>
      ),
    },
    [steps.paymentMethodValidation]: {
      stepperTitle: t('Validate payment method'),
      component: (
        <Container maxWidth="sm">
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.avatarItem}
          >
            <Grid item>
              <Avatar className={classes.avatar}>
                <CreditCardIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {t('Validate payment method')}
              </Typography>
            </Grid>
          </Grid>

          {selectedPlan && (
            <Elements stripe={stripePromise}>
              <PaymentMethodValidation
                planId={selectedPlan.id}
                onPaymentSuccess={handlePaymentSuccess}
              />
            </Elements>
          )}
        </Container>
      ),
    },
  };

  return (
    <div>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Grid container justify="flex-end">
            <Button href={routes.SIGN_IN} color="inherit">
              {t('Login')}
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container component="main">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          className={classes.root}
        >
          <Grid item>{stepsComponents[activeStep].component}</Grid>
          <Grid item></Grid>
          <Grid item>
            <Stepper
              activeStep={Object.values(steps).indexOf(activeStep)}
              alternativeLabel
              className={classes.stepper}
            >
              {Object.values(stepsComponents).map((step) => (
                <Step key={step.stepperTitle}>
                  <StepLabel>{step.stepperTitle}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
