import React from 'react';
import Navigation from 'features/menu/Navigation';
import menuList from 'features/menu';
import Bookings from 'features/booking/Bookings';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Navigation title={t('Bookings')}>
      <Bookings />
    </Navigation>
  );
};
