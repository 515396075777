import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Progress from 'components/Progress';
import theme from 'config/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import App from './app/Index';

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<Progress />}>
          <App />
        </Suspense>
        {/* <PersistGate loading={null} persistor={persistStore(store)}> */}
        {/* </PersistGate> */}
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
