/* eslint-disable no-sparse-arrays */
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HelpIcon from '@material-ui/icons/Help';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import routes from 'routes';
import SettingsIcon from '@material-ui/icons/Settings';

export default (user, t) => [
  {
    items: [
      {
        title: t('Deals'),
        icon: LocalOfferIcon,
        path: routes.DEALS,
      },
      {
        title: t('Bookings'),
        icon: ConfirmationNumberIcon,
        path: routes.BOOKINGS,
      },
      {
        title: t('Restaurant'),
        icon: RestaurantIcon,
        path: routes.RESTAURANT,
      },
      {
        title: t('Account'),
        icon: AccountCircleIcon,
        path: routes.ACCOUNT,
      },
      ,
      {
        title: t('Settings'),
        icon: SettingsIcon,
        path: routes.SETTINGS,
      },
    ],
  },
  {
    items: [
      {
        title: t('FAQ'),
        icon: HelpIcon,
        path: t('url_faq'),
      },
      {
        title: t('Mobile Applications'),
        icon: SmartphoneIcon,
        path: t('url_applications'),
      },
    ],
  },
];
