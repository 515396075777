import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, clear } from './session';

export default () => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const id = localStorage.getItem('userId');

    if (id && token) {
      dispatch(fetchUser());
    } else {
      dispatch(clear());
    }
  }, [dispatch]);

  return session;
};
