import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    displayNotification: (state, action) => {
      const notification = action.payload;
      if (!notification.key) {
        notification.key = new Date().getTime() + Math.random();
      }
      state.notifications.push(notification);
    },
    closeNotification: (state, action) => {
      const key = action.payload;
      state.notifications.splice(
        state.notifications.findIndex((n) => n.key === key),
      );
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
});

const { actions, reducer } = slice;

export const {
  displayNotification,
  closeNotification,
  clearNotifications,
} = actions;

export default reducer;
